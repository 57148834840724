<template>
  <div class="box">
    <div class="banner-box">
      <img
        src="@/img/guibi/tag1.png"
        class="xbanner"
        @click="to('companyReport')"
      />
      <img
        src="@/img/guibi/tag2.png"
        class="xbanner"
        @click="to('consultant')"
      />
    </div>
    <div class="hint">更多服务敬请期待…</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    to(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  min-height: calc(100vh - 90px);
  padding-top: 10px;
  padding-bottom: 80px;
  background: #fafafa;
  .banner-box {
    width: 100%;
    background: #ffffff;
    text-align: center;
    .xbanner {
      width: 315px;
      height: 130px;
      margin-top: 20px;
    }
  }
  .hint {
    font-size: 12px;
    color: #666666;
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
}
</style>
